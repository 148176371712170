html {
  height: 100%;
}

body {
  margin: 0px;
  height: 100%;
}

.controlBar {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 10000;
  background-color: #23272a;
}

.bgImage {
  position: fixed;
  /*
  background-image: url("https://s3-us-west-1.amazonaws.com/willjenkx.com/stuff/Visions_of_Nzoth_-_Key_Art.png");
  */
  background-image: url("https://toastie.dev/resources/background.png");
  height: 100%;
  width: 100%;
  opacity: 0.5;
  z-index: -1;
  /* -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */
  background-size: 100% 100%;
}

.bodyContent {
  position: relative;
}

html {
  background: #23272a;
  -webkit-font-smoothing: antialiased;
  padding-left: 0px;
}

a {
  text-decoration: none;
}

a:link {
  color: #00d9ff;
  white-space: nowrap;
  text-decoration: none, inherit;
}

a:visited {
  color: #00be9f;
  text-decoration: inherit;
}

a:hover {
  color: #00f7ff;
  text-decoration: underline;
}

.guildNameStyle,
.guildNameStyle:visited {
  font-size: 18px;
  color: #e6cc80;
}

.titleBarStuff {
  min-width: 10%;
  min-height: 1vh;
  display: inline-block;
  background-color: #323841;
  font-size: 16px;
  padding-left: 2px;
  text-align: center;
  font-weight: bold;
}

.titleBarStuff:hover {
  background-color: #3e4550;
  color: #00f7ff;
  text-decoration: underline;
  cursor: pointer;
}

.guildTabStuff {
  width: 100%;
  display: inline-block;
  background-color: #323841;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  border: 1px solid grey;
}

h4 {
  text-indent: 8mm;
  color: #e6cc80;
}

.noLogs {
  color: white;
  text-align: center;
}

.whiteTextCenter {
  color: white;
  text-align: center;
}

.logpanels {
  font-family: "Arial", Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.3;
  border-radius: 13px;
  border: 0px solid #aeaeae;
  margin: 3px;
  min-width: 33%;
}

@media (max-width: 550px) { 
  .logpanels {
    width: webkit-fill-available;
    width: -moz-available;
  }
}

@media (max-width: 1250px) { 
  .logpanels {
    min-width: 48%;
  }
}

.logpanelContainer {
  position: relative;
  z-index: 1;
  display: flex;
  /*padding: 5px;*/
  flex-wrap: wrap;
  pointer-events: none;
}

.legendary {
  color: #ff8000;
}
.epic {
  color: #a335ee;
}
.rare {
  color: #0070dd;
}
.magic {
  color: #1eff00;
}

input {
  font-size: 17px;
  border-radius: 3px;
  box-shadow: inset 0 0px 2px #e9e9e9;
  border: 1px solid #aeaeae;
  line-height: 10px;
  padding: 0px 0px 0px;
  text-align: center;
  inline-size: 3cm;
}

.datepickerContainer {
  padding-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.datepickerButton {
  border: 1px solid #aeaeae;
  border-radius: 5px;
  line-height: 10px;
}

@media (max-width: 1080px) { 
  .datepickerContainer {
    padding-top: 0rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}


footer {
  position: fixed;
  bottom: 0px;
  width: 100%;
  text-align: left;
  z-index: 0;
}

.tableReportSummary {
  margin-top: 5px;
  width: 100%;
  pointer-events: all;
  background-color: #2c2f33;
  border-collapse: collapse;
}

.raidnightTableHeaderRow {
  padding: 2px;
  margin-bottom: 2px;
  background-color: #323841;
}

.report-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding: 5px;
  max-width: 9%;
}

/* .report-title {
  
} */

.raidnightTableHeader {
  padding: 2px;
  margin-bottom: 2px;
  background-color: #323841;
}

tr:hover {
  background-color: #323841;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.tableRow0 {
  background-color: #2c2f33;
}

.tableRow1 {
  background-color: #23272a;
}

.tableRow0,
.tableRow1,
.tableReportSummary,
.raidnightTableHeaderRow,
.raidnightTableHeader {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

.tableReportSummary,
caption,
td,
th,
.gridCenter {
  border: 1px solid grey;
}

.gridCenter,
.gridCenter-time {
  color: white;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
}

.gridCenter-time {
  max-width: 22px;
}

.gridFightName {
  text-indent: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 250px;
  white-space: nowrap;
}

@media (max-width: 1080px) {  
  .gridFightName {
    max-width: 40vw;
  }

  .gridCenter-time {
    max-width: 30vw;
  }
}

.hr {
  border-right: 0;
  border-left: 0;
  background-color: purple;
  height: 1px;
  border-top: 1px solid black;
}

.react-datepicker-popper {
  z-index: 3 !important;
}

.imgCopyright {
  color: #ff8000;
  text-align: right;
  position: fixed;
  bottom: 0px;
  right: 0px;
}

.twitchIcon {
  max-width: 15px;
  max-height: 15px;
}

.liveOnTwitch {
  animation-name: shiny;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

@keyframes shiny {
  0% {
    color: pink;
  }
  33% {
    color: violet;
  }
  66% {
    color: blueviolet;
  }
  100% {
    color: pink;
  }
}

.raid-complete {
  animation-name: shiny-raid-complete;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

@keyframes shiny-raid-complete {
  0% {
    color: #ff8000;
  }
  33% {
    color: #a335ee;
  }
  66% {
    color: #e6cc80;
  }
  100% {
    color: #ff8000;
  }
}

.loadSpinner {
  border: 4px solid #7a7f86;
  border-top: 4px solid #ff8000;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  animation: spin 2s linear infinite;
  text-align: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  visibility: hidden;
  width: 0px;
}

.GuildGraveyard {
  position: fixed;
  bottom: 0px;
  width: 100%;
  text-align: left;
  z-index: 0;
  display: flex;
}

#canvas {
  position: fixed;
  top: 0;
  /* width: 100vw;
  height: 100vh; */
  opacity: 0.5;
}

.grey {
  color: grey;
}

.guildSelect {
  border: 1px solid #aeaeae;
  border-radius: 5px;
  line-height: 10px;
}

.active {
  background-color: #a335ee;
  border: none;
  border-radius: 5px;
  color: white;
  font-weight: 700;
}

/* what is consistency for 500 alex */
.mode-select-wrapper {
  display: flex;
  gap: 3px;
  margin-left: 15px;
}

@media (max-width: 1080px) { 
  .mode-select-wrapper {
    gap: 0px;
    margin-left: 0px;
  }

  .mode-select-button {
    font-weight: 100;
  }
}

.twitch-integration-wrapper {
  color: white;
  font-weight: 600;
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row;
  max-width: 80%;
  gap: 2px;
}

.twitch-integration-tab {
  background-color: #6441a5;
  width: 15em;
  /* height: 3em; */
  text-align: center;
  display: flex;
  flex-direction: row;
  border: 1px solid #ff8000;
  align-items: center;
  /* justify-content: center; */
}

.twitch-integration-tab:hover {
  background-color: #8657dc;
  cursor: pointer;
}

.live-image-wrapper:hover {
  background-color: #9d83cf;
  cursor: pointer;
}

.twitch-logo {
  /* min-width: 2em;
  max-width: 2em; */
  width: 56px;
}

.live-image-wrapper {
  display: flex;
  flex-direction: row;
  gap: 4px;
  background-color: #323841;
  border: 1px solid white;
  min-height: 3em;
}

.twitch-integration-name {
  margin-left: 1em;
}

.hidden {
  display: none;
}

.currently-live-text {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  margin-left: 10px;
}

.ranking-svg {
  width: 14px;
  color:white;
}

.guild-name-row {
  display: flex;
  margin-bottom: 0px;
  justify-content: center;
  position: relative;
}

.guild-name-row-child {
  display: flex;
  gap: 3px;
  border: 0px solid grey; 
  /* this overwrites the grey border on all td elements */
  padding: 0px;
}

@media screen and (max-width: 1080px) {
  .guild-name-row {
    justify-content: inherit;
  }
  
}


.guild-rankings {
  position: absolute;
  right: 0;
  margin-right: 5px;
}

/* .guild-name {
  margin-left: auto;
  margin-right: auto;
} */

.interactable-header {
  user-select: none;
  cursor: pointer;
  background-color: #3e4550;
}

.interactable-header:hover {
 background-color: #323841
}

.custom-guilds-wrapper {
  color: white;
  font-weight: 600;
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  /* max-width: 80%; */
  /* gap: 2px; */
  z-index: 10001;
}

.custom-guild-svg-wrapper {
  display: flex;
  flex-direction: row;
  gap: 4px;
  background-color: white;
  border: 3px solid green;
  min-height: 3em;
  width: 46px;
}

.custom-guild-svg-wrapper:hover {
  background-color: lightgreen;
  cursor: pointer;
}

.custom-guilds-form-wrapper {
  z-index: 151;
  position: fixed;
  background-color: #323841;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-guilds-modal-backdrop {
  display: flex;
  align-items: center;
  justify-content: center;
}


.custom-guilds-modal-backdrop:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  content: '';
}

.custom-guilds-form {
  padding: 70px;
  position: absolute;
  top: 25%;
  width: 35%;
  height: auto;
  background: rgba(35, 39, 42);
  border: 1px solid purple;
  box-shadow: 0.2px 0.6px 0.5px 0.5px;
  z-index: 1;
}

@media (max-width: 1080px) {
  .custom-guilds-form {
   width: 100%;
  }

  .custom-guilds-done {
    background-color: green;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    border: solid 1px purple;
    padding: 10px;
    user-select: none;
    cursor: pointer;
    color: white;
    font-weight: 800;
  }
}

.custom-guilds-inputs {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.custom-guilds-input {
  width: 25rem;
}

.custom-guild-item {
  display: flex;
  gap: 5px;
  justify-content: space-between;
  width: 50%
}

.potential-guilds-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-top: 10px;
}

.custom-guilds-done {
  background-color: green;
  position: absolute;
  right: 0;
  bottom: 0;
  border: solid 1px purple;
  padding: 10px;
  user-select: none;
  cursor: pointer;
  color: white;
  font-weight: 800;
}

.add-custom-guild {
  cursor: pointer;
}

.remove-custom-guild {
  cursor: pointer;
}

.remove-custom-guild:hover, .add-custom-guild:hover {
  background-color: #323841;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.spinning {
  animation: spin 2s linear infinite;
}

.guild-best-prog {
  color: white;
  display: flex;
  border-bottom: none;
  border-left: none;
  border-right: none;
  justify-content: center;
  gap: 10px;
}

.guild-best-prog-child {
  margin-left: 5px;
}


.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip-content {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the tooltip above the text */
  left: 50%;
  margin-left: -60px; /* Use half the width to center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltip-content {
  visibility: visible;
  opacity: 1;
}

.tooltip-header {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.tooltip-title {
  font-weight: bold;
}

.tooltip-close {
  cursor: pointer;
}

.tooltip-body {
  padding: 5px;
}

.error-box {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 150px;
  color: white;
  background-color: #3e4550;
  border: 1px solid grey;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}